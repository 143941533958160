<script setup lang="ts">
import { cn } from '@/lib/utils'
import {
  SwitchRoot,
  type SwitchRootEmits,
  type SwitchRootProps,
  SwitchThumb,
  useForwardPropsEmits,
} from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<SwitchRootProps & { 
  class?: HTMLAttributes['class'],
  size?: 'default' | 'sm' | 'xs'
}>()

const emits = defineEmits<SwitchRootEmits>()

const delegatedProps = computed(() => {
  const { class: _, size: __, ...delegated } = props
  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const sizes = {
  default: {
    switch: 'h-5 w-9',
    thumb: 'h-4 w-4 data-[state=checked]:translate-x-4'
  },
  sm: {
    switch: 'h-4 w-7',
    thumb: 'h-3 w-3 data-[state=checked]:translate-x-3'
  },
  xs: {
    switch: 'h-3 w-5',
    thumb: 'h-2 w-2 data-[state=checked]:translate-x-2'
  }
}

const sizeClasses = computed(() => sizes[props.size || 'default'])
</script>

<template>
  <SwitchRoot
    v-bind="forwarded"
    :class="cn(
      'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
      sizeClasses.switch,
      props.class,
    )"
  >
    <SwitchThumb
      :class="cn('pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0', sizeClasses.thumb)"
    />
  </SwitchRoot>
</template>
