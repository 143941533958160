import { defineStore } from 'pinia'
import { GET, PUT } from '../utils/api'
import localForage from 'localforage'
import { useAuthStore } from './auth'
import { useRestaurantsStore } from './restaurants'

export const useAccountsStore = defineStore('accounts', {
  state: () => ({
    accounts: []
  }),

  getters: {
    currentAccountId() {
      const authStore = useAuthStore()
      return authStore.accountId
    },
    currentAccount() {
      return this.accounts.find(acc => acc.id === this.currentAccountId)
    },
    allAccounts: (state) => state.accounts,
    allAccountsMap: (state) => {
      return new Map(state.accounts.map(account => [account.id, account]))
    }
  },

  actions: {
    async getCustomDomainStatus() {
      return await GET('accounts/:accountId/restaurants/:restaurantId/custom-domain/status')
    },

    async clearAccountCache() {
      const cacheKeyPatterns = [':cache:getAccounts', ':cache:getAccountsTime']
      const keys = await localForage.keys()
      for (const key of keys) {
        if (cacheKeyPatterns.some(pattern => key.includes(pattern))) {
          await localForage.removeItem(key)
        }
      }
    },

    async getAccounts(useCache = true) {
      const authStore = useAuthStore()
      const jwt = authStore.token?.substring(0, 10)
      const cacheDuration = 86400000
      const now = new Date().getTime()
      const cacheKey = `${jwt}:cache:getAccounts`
      const cacheTimeKey = `${jwt}:cache:getAccountsTime`
      
      console.log(`🔄 [Accounts Store] Fetching accounts. Use cache: ${useCache}`)
      
      const cache = await localForage.getItem(cacheKey)
      const lastFetched = await localForage.getItem(cacheTimeKey)
      const cacheIsValid = lastFetched && (now - lastFetched) < cacheDuration

      if (useCache && cache && cacheIsValid) {
        try {
          console.log('🗄️ [Accounts Store] Using cached accounts data.')
          this.setAccounts(cache.accounts)
          const restaurantsStore = useRestaurantsStore()
          await restaurantsStore.getRestaurant()
          return cache
        } catch (err) {
          console.warn('⚠️ [Accounts Store] Failed to get accounts from cache:', err)
          return this.getAccounts(false)
        }
      }

      try {
        const response = await GET('accounts')
        console.log('✅ [Accounts Store] Fetched accounts:', response)
        
        if (!response || !Array.isArray(response.accounts)) {
          console.error('❌ [Accounts Store] Unexpected API response structure:', response)
          throw new Error('Unexpected API response structure')
        }

        if (useCache && jwt) {
          await this.clearAccountCache()
          try {
            await localForage.setItem(cacheKey, response)
            await localForage.setItem(cacheTimeKey, now)
            console.log('🗄️ [Accounts Store] Cached accounts data.')
          } catch (err) {
            console.error('❌ [Accounts Store] Error caching accounts data:', err)
          }
        }
        
        this.setAccounts(response.accounts)
        return response
      } catch (error) {
        console.error('❌ [Accounts Store] Error fetching accounts:', error)
        throw error
      }
    },

    async updateAccount(account) {
      let payload = { ...account }
      if (payload.subscription) {
        payload.subscription = payload.subscription.database_name
      }
      try {
        const response = await PUT('accounts/:accountId', payload)
        this.updateAccountInStore(account)
        await this.clearAccountCache()
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    addAccountToStore(accountToAdd) {
      this.accounts.push(accountToAdd)
    },

    addRestaurantToAccount(data) {
      const account = this.accounts.find(acc => acc.id === data.accountId)
      if (account) {
        account.restaurants.push(data.restaurantToAdd)
      }
    },

    removeRestaurantFromAccount(data) {
      const account = this.accounts.find(acc => acc.id === data.accountId)
      if (account) {
        const index = account.restaurants.findIndex(restaurant => restaurant.id === data.restaurantToRemove)
        if (index !== -1) {
          account.restaurants.splice(index, 1)
        }
      }
    },

    setAccount(id) {
      const authStore = useAuthStore()
      localStorage.setItem('accountId', id)
      authStore.accountId = id
    },

    updateAccountInStore(account) {
      const index = this.accounts.findIndex(x => x.id === account.id)
      if (index !== -1) {
        Object.assign(this.accounts[index], account)
      }
    },

    setAccounts(accounts) {
      this.accounts = accounts
      console.log('🔄 [Accounts Store] Accounts set:', accounts)
      
      const authStore = useAuthStore()
      window.Trengo = window.Trengo || {}
      window.Trengo.contact_data = {
        email: authStore.user?.email,
        name: `${authStore.user?.first_name} ${authStore.user?.last_name}`,
        phone: authStore.user?.phone_number,
        custom_fields: [{
          field_id: 178573,
          value: accounts[0]?.name || null
        }, {
          field_id: 178558,
          value: accounts[0]?.subscription?.database_name || null
        }, {
          field_id: 325188,
          value: accounts[0]?.restaurants?.length ? `https://admin.orderli.com/orders?accountId=${accounts[0].id}&restaurantId=${authStore.restaurantId || accounts[0].restaurants[0].id}` : 'https://admin.orderli.com/orders'
        }]
      }
      console.log('🔄 [Accounts Store] Trengo contact data updated.')
    },
  }
})
