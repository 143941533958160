import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { useAuthStore } from '../auth'
import { toast } from 'vue-sonner'

export const useMenuItemsStore = defineStore('menuItems', {
  state: () => ({
    items: [],
    itemTypes: null, // Initial state is null, when loaded and no item types it will be an empty array
    itemTypesIsLoading: false,
    showItemModalId: null,
  }),

  getters: {
    itemsAndOptionItemsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.items.filter(x => x.restaurant_id === authStore.restaurantId)
    },
    itemsOfCurrentRestaurant() {
      return this.itemsAndOptionItemsOfCurrentRestaurant.filter(x => !x.is_option)
    },
    optionItemsOfCurrentRestaurant() {
      return this.itemsAndOptionItemsOfCurrentRestaurant.filter(x => x.is_option)
    },
    itemsAndOptionsMap() {
      return new Map(this.itemsAndOptionItemsOfCurrentRestaurant.map(element => [element.id, element]))
    },
    itemsMap() {
      return new Map(this.itemsOfCurrentRestaurant.map(element => [element.id, element]))
    },
    optionItemsMap() {
      return new Map(this.optionItemsOfCurrentRestaurant.map(element => [element.id, element]))
    },
    itemsAndOptionsPosIdMap() {
      return new Map(this.itemsAndOptionItemsOfCurrentRestaurant.map(element => [element.pos_id, element]))
    },
  },

  actions: {
    async getItemTypes() {
      if (this.itemTypesIsLoading || this.itemTypes !== null) {
        return
      }

      this.itemTypesIsLoading = true
      try {
        const response = await GET(`accounts/:accountId/restaurants/:restaurantId/item-types`)
        this.itemTypes = response.item_types.sort((a, b) => a.id - b.id)
        return response
      } catch (error) {
        console.error('Failed to fetch item types:', error)
        throw error
      } finally {
        this.itemTypesIsLoading = false
      }
    },

    async createItemType(itemType) {
      const response = await POST(`accounts/:accountId/restaurants/:restaurantId/item-types`, itemType)
      this.itemTypes.push(response)
      return response
    },

    async updateItemType(itemType) {
      return await PUT(`accounts/:accountId/restaurants/:restaurantId/item-types`, { item_types: [itemType] })
    },

    async newItem(item, config = undefined) {
      try {
        const newItem = await POST('accounts/:accountId/menu/items', item, config)
        newItem.categories = []; // This might cause issues when duplicating in menu editor
        if (!config) this.items.push(newItem) // Only push to store if no accountId or restaurantId was provided (for duplicate to locations)
        return newItem
      } catch (error) {
        console.error('Failed to create item:', error)
        throw error
      }
    },

    preprocessItemForApi(item) {
      const processedItem = { ...item }
      
      if ('item_type' in processedItem) {
        processedItem.item_type_id = processedItem.item_type?.id ?? null
        delete processedItem.item_type
      }
      
      return processedItem
    },

    async updateItem(itemArray) {
      try {
        const processedItems = itemArray.map(item => this.preprocessItemForApi(item))

        const response = await PUT('accounts/:accountId/menu/items', { items: processedItems })
        
        itemArray.forEach(updatedItem => {
          const index = this.items.findIndex(item => item.id === updatedItem.id)
          if (index !== -1) {
            this.items[index] = { ...this.items[index], ...updatedItem }
          }
        })
        
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async deleteItem(itemId) {
      try {
        const response = await DELETE(`accounts/:accountId/menu/items/${itemId}`)
        const itemName = this.itemsMap.get(itemId)?.name
        this.items = this.items.filter(item => item.id !== itemId)
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async getVatPercentages() {
      return await GET(`accounts/:accountId/menu/:restaurantId/vat-percentages`)
    },

    async updateVatPercentages(obj) {
      return await PUT(`accounts/:accountId/menu/:restaurantId/vat-percentages`, obj)
    },

    setItemModal(payload) {
      this.showItemModalId = payload
    },

    updateItemCategoryRelationships(categoryIds, itemIds, action) {
      this.items.forEach(item => {
        const itemInCategories = itemIds.includes(item.id)
        const hasCategories = Array.isArray(item.categories)

        if (!hasCategories) {
          item.categories = []
        }

        categoryIds.forEach(categoryId => {
          const categoryIndex = item.categories.indexOf(categoryId)
          const isCategoryLinked = categoryIndex !== -1

          if (action === 'add' && itemInCategories && !isCategoryLinked) {
            item.categories.push(categoryId)
          } else if (action === 'remove' && !itemInCategories && isCategoryLinked) {
            item.categories.splice(categoryIndex, 1)
          } else if (action === 'set') {
            if (itemInCategories && !isCategoryLinked) {
              item.categories.push(categoryId)
            } else if (!itemInCategories && isCategoryLinked) {
              item.categories.splice(categoryIndex, 1)
            }
          }
        })
      })
    },
  },
})
