import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { useAuthStore } from '../auth'
import { toast } from 'vue-sonner'

export const useMenuOptionGroupsStore = defineStore('menuOptionGroups', {
  state: () => ({
    optionGroups: [],
    optionGroupTypeOptions: [
      { value: 'Select', label: 'Select', icon: 'fas fa-fw fa-circle-check' },
      { value: 'Addons', label: 'Addons', icon: 'fas fa-fw fa-square-check' },
      { value: 'Glasses', label: 'Glasses', icon: 'fas fa-fw fa-champagne-glasses' },
    ],
  }),

  getters: {
    optionGroupsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.optionGroups
        .filter(x => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    optionGroupsMap() {
      return new Map(this.optionGroupsOfCurrentRestaurant.map(element => [element.id, element]))
    },
  },

  actions: {
    async newOptionGroup(optionGroup) {
      const response = await POST('accounts/:accountId/menu/options', optionGroup)
      let newOptionGroup = response.option;
      newOptionGroup.items = [];
      this.optionGroups.push(newOptionGroup)
      return newOptionGroup
    },

    async updateOptionGroup(optionGroupArray) {
      try {
        const response = await PUT('accounts/:accountId/menu/options', { options: optionGroupArray })
        
        // Update local state using Vue's reactivity
        optionGroupArray.forEach(updatedOptionGroup => {
          const index = this.optionGroups.findIndex(group => group.id === updatedOptionGroup.id)
          if (index !== -1) {
            // Use Vue's reactivity system to update the array
            this.optionGroups[index] = { ...this.optionGroups[index], ...updatedOptionGroup }
          }
        })
        
        return response
      } catch (error) {
        console.error(error)
        toast.error('Failed to update option group(s)')
        throw error
      }
    },

    async deleteOptionGroup(optionGroupId) {
      try {
        const response = await DELETE(`accounts/:accountId/menu/options/${optionGroupId}`)
        const optionGroupName = this.optionGroupsMap.get(optionGroupId)?.name
        // toast.success(`Option Group ${optionGroupName} Deleted`)
        this.optionGroups = this.optionGroups.filter(group => group.id !== optionGroupId)
        return response
      } catch (error) {
        console.error(error)
        toast.error('Failed to delete option group')
        throw error
      }
    },

    // Helper method to update option group-item relationships
    updateOptionGroupItemRelationships(itemIds, optionGroupIds, action) {
      // action can be 'add', 'remove', or 'set'
      this.optionGroups.forEach(group => {
        const groupInItems = optionGroupIds.includes(group.id)
        const hasItems = Array.isArray(group.items)

        // Initialize items array if it doesn't exist
        if (!hasItems) {
          group.items = []
        }

        itemIds.forEach(itemId => {
          const itemIndex = group.items.indexOf(itemId)
          const isItemLinked = itemIndex !== -1

          if (action === 'add' && groupInItems && !isItemLinked) {
            group.items.push(itemId)
          } else if (action === 'remove' && !groupInItems && isItemLinked) {
            group.items.splice(itemIndex, 1)
          } else if (action === 'set') {
            if (groupInItems && !isItemLinked) {
              group.items.push(itemId)
            } else if (!groupInItems && isItemLinked) {
              group.items.splice(itemIndex, 1)
            }
          }
        })
      })
    },
  },
})
