import axios from 'axios';
import { getAccountId, getRestaurantId } from '../stores/auth';

const DEFAULT_BASE_URL = 'dashboard/api/v1';

const replaceUrlParams = (url, config = {}) => {
  const accountId = config.accountId || getAccountId()
  const restaurantId = config.restaurantId || getRestaurantId()
  return url.replace(':accountId', accountId).replace(':restaurantId', restaurantId);
};

const createApiMethod = (method) => async (url, data = {}, config = {}) => {
  const { base_url, accountId, restaurantId, ...restConfig } = config;
  const fullUrl = `${base_url || DEFAULT_BASE_URL}/${replaceUrlParams(url, { accountId, restaurantId })}`;

  try {
    const response = await axios[method](fullUrl, method === 'get' ? { params: data } : data, restConfig);
    return response.data;
  } catch (error) {
    console.error(`${method.toUpperCase()} error:`, error);
    throw error;
  }
};

export const GET = createApiMethod('get');
export const POST = createApiMethod('post');
export const PUT = createApiMethod('put');
export const DELETE = createApiMethod('delete');
