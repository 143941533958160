import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { toast } from 'vue-sonner'
import { useAuthStore } from '../auth'

export const useMenuCategoriesStore = defineStore('menuCategories', {
  state: () => ({
    categories: [],
    quickSelectOptions: [
      {
        label: 'Drinks',
        options: [
          {
            value: 'beer',
            label: 'Beer',
          },
          {
            value: 'beer-glass',
            label: 'Beer glass',
          },
          {
            value: 'pint',
            label: 'Pint',
          },
          {
            value: 'beer-tap',
            label: 'Beer tap',
          },
          {
            value: 'beer-can',
            label: 'Beer can',
          },
          {
            value: 'bottle-cap',
            label: 'Bottle cap',
          },
          {
            value: 'champagne',
            label: 'Champagne',
          },
          {
            value: 'cocktail',
            label: 'Cocktail',
          },
          {
            value: 'cocktail-2',
            label: 'Cocktail alternative',
          },
          {
            value: 'martini-glass',
            label: 'Martini Glass',
          },
          {
            value: 'liquor',
            label: 'Liquor',
          },
          {
            value: 'moonshine',
            label: 'Moonshine',
          },
          {
            value: 'whiskey-bottle',
            label: 'Whiskey Bottle',
          },
          {
            value: 'whiskey',
            label: 'Whiskey Glass',
          },
          {
            value: 'shot',
            label: 'Shot',
          },
          {
            value: 'wine',
            label: 'Wine',
          },
          {
            value: 'wine-bottle',
            label: 'Wine Bottle',
          },
          {
            value: 'no-alcohol',
            label: 'No alcohol',
          },
          {
            value: 'hot-drinks',
            label: 'Hot Drinks',
          },
          {
            value: 'tea',
            label: 'Tea',
          },
          {
            value: 'soda',
            label: 'Soda',
          },
          {
            value: 'soda-2',
            label: 'Soda Alternative',
          },
          {
            value: 'lemonade',
            label: 'Lemonade',
          },
          {
            value: 'juice',
            label: 'Juice',
          },
          {
            value: 'milk',
            label: 'Milk',
          },
          {
            value: 'refreshments',
            label: 'Refreshments',
          },
          {
            value: 'water-tap',
            label: 'Water tap',
          },
          {
            value: 'energy-drink',
            label: 'Energy Drink',
          },
          {
            value: 'coffee-beans',
            label: 'Coffee Beans',
          },
          {
            value: 'coffee-to-go',
            label: 'Coffee To Go',
          },
        ],
      },
      {
        label: 'Food',
        options: [
          {
            value: 'baguette',
            label: 'Baguette',
          },
          {
            value: 'bread',
            label: 'Bread',
          },
          {
            value: 'pancakes',
            label: 'Pancakes',
          },
          {
            value: 'cake',
            label: 'Cake',
          },
          {
            value: 'pie',
            label: 'Pie',
          },
          {
            value: 'cookie',
            label: 'Cookie',
          },
          {
            value: 'croissant',
            label: 'Croissant',
          },
          {
            value: 'sweets',
            label: 'Sweets',
          },
          {
            value: 'sandwich',
            label: 'Sandwich',
          },
          {
            value: 'pizza',
            label: 'Pizza',
          },
          {
            value: 'salad',
            label: 'Salad',
          },
          {
            value: 'ricebowl',
            label: 'Ricebowl',
          },
          {
            value: 'noodles',
            label: 'Noodles',
          },
          {
            value: 'soup',
            label: 'Soup',
          },
          {
            value: 'chickenbox',
            label: 'Chicken Box',
          },
          {
            value: 'fried_rice',
            label: 'Fried Rice',
          },
          {
            value: 'fondue',
            label: 'Fondue',
          },
          {
            value: 'tapas',
            label: 'Tapas',
          },
          {
            value: 'eggs',
            label: 'Eggs',
          },
          {
            value: 'spaghetti',
            label: 'Spaghetti',
          },
          {
            value: 'sushi',
            label: 'Sushi',
          },
          {
            value: 'taco',
            label: 'Taco',
          },
          {
            value: 'wrap',
            label: 'Wrap',
          },
          {
            value: 'ham',
            label: 'Ham',
          },
          {
            value: 'meat',
            label: 'Meat',
          },
          {
            value: 'cow-beef',
            label: 'Cow/beef',
          },
          {
            value: 'seafood',
            label: 'Seafood',
          },
          {
            value: 'fish',
            label: 'Fish',
          },
          {
            value: 'paella',
            label: 'Paella',
          },
          {
            value: 'turkey',
            label: 'Turkey',
          },
          {
            value: 'poultry',
            label: 'Poultry',
          },
          {
            value: 'chicken',
            label: 'Chicken',
          },
          {
            value: 'lamb-rack',
            label: 'Lamb rack',
          },
          {
            value: 'kebab',
            label: 'Kebab',
          },
          {
            value: 'fries',
            label: 'Fries',
          },
          {
            value: 'bitterballen',
            label: 'Bitterballen',
          },
          {
            value: 'burger',
            label: 'Burger',
          },
          {
            value: 'hamburger',
            label: 'Burger Alternative',
          },
          {
            value: 'hotdog',
            label: 'Hot dog',
          },
          {
            value: 'nachos',
            label: 'Nachos',
          },
          {
            value: 'cheese',
            label: 'Cheese ',
          },
          {
            value: 'refreshments',
            label: 'Refreshments',
          },
          {
            value: 'icecream',
            label: 'Ice cream',
          },
          {
            value: 'popsicle',
            label: 'Popsicle',
          },
        ],
      },
      {
        label: 'Miscellaneous',
        options: [
          {
            value: 'chocolate',
            label: 'Chocolate',
          },
          {
            value: 'candy',
            label: 'Candy',
          },
          {
            value: 'fresh',
            label: 'Fresh',
          },
          {
            value: 'vegetarian',
            label: 'Vegetarian',
          },
          {
            value: 'chili-pepper',
            label: 'Chili pepper',
          },
          {
            value: 'flame',
            label: 'Flame',
          },
          {
            value: 'water',
            label: 'Water',
          },
          {
            value: 'explosion',
            label: 'Explosion',
          },
          {
            value: 'bomb',
            label: 'Bomb',
          },
          {
            value: 'flower',
            label: 'Flower',
          },
          {
            value: 'dumbbell',
            label: 'Dumbbell',
          },
          {
            value: 'toothbrush',
            label: 'Toothbrush',
          },
          {
            value: 'bathtub',
            label: 'Bathtub',
          },
          {
            value: 'headphones',
            label: 'Headphones',
          },
          {
            value: 'rubber-duck',
            label: 'Rubber duck',
          },
          {
            value: 'disco-ball',
            label: 'Disco ball',
          },
          {
            value: 'confetti',
            label: 'Confetti',
          },
          {
            value: 'gift',
            label: 'Gift',
          },
          {
            value: 'balloons',
            label: 'Balloons',
          },
          {
            value: 'eye',
            label: 'Eye',
          },
          {
            value: 'sharing',
            label: 'Sharing',
          },
          {
            value: 'olive-oil',
            label: 'Olive oil',
          },
          {
            value: 'halal',
            label: 'Halal',
          },
          {
            value: '1',
            label: '1',
          },
          {
            value: '2',
            label: '2',
          },
          {
            value: '3',
            label: '3',
          },
          {
            value: '4',
            label: '4',
          },
          {
            value: '5',
            label: '5',
          },
        ],
      },
    ],
    categoryStyles: [
      {
        value: 'single',
        label: 'Standard',
      },
      {
        value: 'double',
        label: 'Double',
      },
      {
        value: 'message',
        label: 'Message',
      },
      {
        value: 'reservation',
        label: 'Reservation',
      },
      {
        value: 'spin-wheel',
        label: 'Spinner'
      },
      {
        value: 'welcome-message',
        label: 'Welcome message (beta)',
      },
      {
        value: 'welcome-message-v2',
        label: 'Welcome message (beta v2)',
      },
      {
        value: 'single-scaleimage',
        label: 'Standard - Scale images',
      },
      {
        value: 'single-banner',
        label: 'Standard - Banner',
      },
      {
        value: 'banner',
        label: 'Banner',
      },
      {
        value: 'double-banner',
        label: 'Double - Banner',
      },
      {
        value: 'image-only',
        label: 'Image only (beta)',
      },
    ],
  }),

  getters: {
    categoriesOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.categories
        .filter((x) => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    categoriesMap() {
      return new Map(this.categoriesOfCurrentRestaurant.map((element) => [element.id, element]))
    },
  },

  actions: {
    async newCategory(category, config = undefined) {
      try {
        const response = await POST('accounts/:accountId/menu/categories', category, config)
        let newCategory = response.category;
        newCategory.cards = []; // This might cause issues when duplicating in menu editor
        if (!config) this.categories.push(newCategory) // Only push to store if no accountId or restaurantId was provided (for duplicate to locations)
        return newCategory
      } catch (error) {
        console.error('Failed to create category:', error)
        throw error
      }
    },

    async updateCategory(categoryArray) {
      try {
        const response = await PUT('accounts/:accountId/menu/categories', { categories: categoryArray })
        
        // Update local state using Vue's reactivity
        categoryArray.forEach(updatedCategory => {
          const index = this.categories.findIndex(category => category.id === updatedCategory.id)
          if (index !== -1) {
            // Use Vue's reactivity system to update the array
            this.categories[index] = { ...this.categories[index], ...updatedCategory }
          }
        })
        
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async deleteCategory(categoryId) {
      try {
        const response = await DELETE(`accounts/:accountId/menu/categories/${categoryId}`)
        const categoryName = this.categoriesMap.get(categoryId)?.name
        this.categories = this.categories.filter(category => category.id !== categoryId)
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    // New helper method to update category-card relationships
    updateCategoryCardRelationships(cardIds, categoryIds, action) {
      // action can be 'add', 'remove', or 'set'
      this.categories.forEach(category => {
        const categoryInCards = categoryIds.includes(category.id)
        const hasCards = Array.isArray(category.cards)

        // Initialize cards array if it doesn't exist
        if (!hasCards) {
          category.cards = []
        }

        cardIds.forEach(cardId => {
          const cardIndex = category.cards.indexOf(cardId)
          const isCardLinked = cardIndex !== -1

          if (action === 'add' && categoryInCards && !isCardLinked) {
            category.cards.push(cardId)
          } else if (action === 'remove' && !categoryInCards && isCardLinked) {
            category.cards.splice(cardIndex, 1)
          } else if (action === 'set') {
            if (categoryInCards && !isCardLinked) {
              category.cards.push(cardId)
            } else if (!categoryInCards && isCardLinked) {
              category.cards.splice(cardIndex, 1)
            }
          }
        })
      })
    },
  },
})